import 'ol/ol.css';
import { register } from 'ol/proj/proj4';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Map, View, Feature, Overlay } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Point, LineString, Circle } from 'ol/geom';
import { Style, Stroke, Circle as CircleStyle, Fill } from 'ol/style';
import { defaults as defaultControls } from 'ol/control';

proj4.defs('EPSG:3031', '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
register(proj4);

const MAX_ZOOM = 12;
const MIN_ZOOM = 3;
var current_zoom = 3;

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  controls: defaultControls({ zoom: false }),
  view: new View({
    projection: 'EPSG:3031',
    center: [0, 0],
    zoom: MIN_ZOOM,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    extent: [-15037508, -10037508, 15037508, 10037508],
    rotation: 0
  })
});

//note - using a slider might be easier in the long run in terms of user interaction
//currently limited to showing data by the month, a slider would show smoother transitions
// - - - - - - - - - - - - - - - - - - - start of user date interaction - - - - - - - - - - - - - - - - - - -

//sets up month in datetimeformat(MM)
const months_dictionary=[
  "01", 
  "02",
  "03",
  "04", 
  "05",
  "06",
  "07", 
  "08",
  "09",
  "10", 
  "11",
  "12"
];



const months_options = document.getElementsByClassName('month');
const year_selector = document.getElementById("year-selector");

//find most recently available datapoint's year, month from db

var month;
var year;

$.ajax({
  url: '/date_extent.php',
  method: 'GET', 
  dataType: 'json',
  success: function(data) {
    year = data.Year;
    month = data.Month;
    console.log(data);
  },
  error: function(xhr, status, error) {
    console.error('Error Details:');
    console.error('Status:', status);
    console.error('Error:', error);
    console.error('Response Text:', xhr.responseText);
    console.error('Status Code:', xhr.status);
    console.error('Response Headers:', xhr.getAllResponseHeaders());
}
});
//set year in html to most recent year
for(let i = 0; i < year_selector.length; i++){
  if(year_selector[i].value == year){
    year_selector[i].selected = true;
  }
}

//months_options[month-1].style.fontWeight = "bold";
//add on - click listener so when user clicks on month, new database query sent and map updates, highlights selected month
for(let i = 0; i < 12; i++){
  months_options[i].onclick = function(){
    for(let j = 0; j < 12; j++){
      months_options[j].style.fontWeight = "normal";
    }
    month = months_dictionary[i];//converting month(Jan) to number(01)
    months_options[i].style.fontWeight = "bold";//would enchance user experience if month is highlighted when month is selected 
    asynch_Query();
  };
}

//add on - select listener so when user changes year, new database query sent and map updates
year_selector.addEventListener("change", function() {
  year=year_selector.value;
  asynch_Query();
});
// - - - - - - - - - - - - - - - - - - - end of user date interaction - - - - - - - - - - - - - - - - - - -
var url;// used in calling php

function asynch_Query(){
  url = '/sqlQuery.php?year='+year+'&month='+month+'&zoom='+map.getView().getZoom();
  $.ajax({
    url: url, 
    method: 'GET', 
    dataType: 'json',
    success: function(data) {
      transformData(data);
  
      plotDives(data, map);
      console.log(data);
      console.log('please remove me; ' + url);
    },
    error: function(xhr, status, error) {
      console.error('Error Details:');
      console.error('Status:', status);
      console.error('Error:', error);
      console.error('Response Text:', xhr.responseText);
      console.error('Status Code:', xhr.status);
      console.error('Response Headers:', xhr.getAllResponseHeaders());
    }
  })
}

var mapDiv = document.getElementById('map');
var scrollTimeout;

mapDiv.addEventListener('wheel', function(event) {
  clearTimeout(scrollTimeout);

  scrollTimeout = setTimeout(function() {
    if(map.getView().getZoom() > 12){//user over-zoomed
      map.getView().setZoom(12);
    }
    if(Math.abs(map.getView().getZoom() - Math.floor(current_zoom)) > 1){
      asynch_Query();//only call database when zoom difference greater than 1
      current_zoom = map.getView().getZoom();
    };
  }, 100); // amount(ms) to delay doing fetch calls too often

});

function plotDives(data, map) {
  //Remove any layers before plotting new dives
  const layers = map.getLayers().getArray();
  for (let i = layers.length - 1; i >= 0; i--){
    if (layers[i] instanceof VectorLayer) {
      map.removeLayer(layers[i]);
    }
  }

  var features = [];
  var lineCoordinates = [];

  data.paths.forEach(function(sealPath){
    sealPath.sealPath.dives.forEach(function(dive){
      var xy = dive.coord;

      var feature = new Feature({
        geometry: new Point(xy),
        diveID: dive.diveID
      });

      features.push(feature);

      lineCoordinates.push(xy);
    });

    var lineFeature = new Feature({
      geometry: new LineString(lineCoordinates)
    });

    features.push(lineFeature);

    lineCoordinates = [];
  });

  var vectorSource = new VectorSource({
    features: features
  });

  var vectorLayer = new VectorLayer({
    source: vectorSource,
    style: function (feature) {
      if (feature.getGeometry().getType() === 'LineString') {
        return new Style({
            stroke: new Stroke({
                color: 'blue',
                width: 2
            })
        });
      } else if (feature.getGeometry().getType() === 'Point') {
          return new Style({
              image: new CircleStyle({
                  radius: map.getView().getZoom()/2,
                  fill: new Fill({color: '#153acf'}),
                  stroke: new Stroke({color: 'black', width: 1})
              })
          });
      }
    }
  });

  map.addLayer(vectorLayer);
}

const element = document.getElementById('popup');
const trackOptions = document.getElementById('track-options');//still not sure what this is for
const popup = new Overlay({
  element: element,
  stopEvent: false,
});
map.addOverlay(popup);

function formatCoordinate(data) {
  return `
    <table>
      <tbody>
      <tr><th>Seal-ID</th><td>${data.sealID}</td></tr>
        <tr><th>Latitude</th><td>${data.coordLat}</td></tr>
        <tr><th>Longitude</th><td>${data.coordLon}</td></tr>
        <tr><th>Max-Depth</th><td>${data.maxDepth}</td></tr>
        <tr><th>Min-Temp</th><td>${data.minTemp}</td></tr>
        <tr><th>Max-Temp</th><td>${data.maxTemp}</td></tr>
        <tr><th>Min-Salinity</th><td>${data.minSal}</td></tr>
        <tr><th>Max-Salinity</th><td>${data.maxSal}</td></tr>
      </tbody>
    </table>`;
}

function updateTrackOptions(coordinate) {
  document.getElementById("track-options-longitude").textContent=coordinate[0].toFixed(2);
  document.getElementById("track-options-latitude").textContent=coordinate[1].toFixed(2);
  document.getElementById("track-options-temperature").textContent="brrrr";
  document.getElementById("track-options-sealId").textContent="999999";
  document.getElementById("track-options-sealSpecies").textContent="spheal";
  document.getElementById("track-options-diveDepth").textContent="45m";
}

//user clicks on point
let popover;

map.on('click', function (event) {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }

  const feature = map.getFeaturesAtPixel(event.pixel)[0];

  if (!feature) {
    return;
  }

  const diveID = feature.get('diveID');
  const coordinate = feature.getGeometry().getCoordinates();

  popup.setPosition([
    coordinate[0],
    coordinate[1]
  ]);

  console.log(`Dive ID: ${diveID}`);
  $.ajax({
    url: 'divePoint.php?q='+month, method: 'GET', dataType: 'json',
    data: {diveID: diveID},
    success: function(data) {
      const sealID = data.SealID;
      const coordLat = data.Lat;
      const coordLon = data.Lon
      const maxDepth = data.MaxDepth;
      const minTemp = data.MinTemp;
      const maxTemp = data.MaxTemp;
      const minSal = data.MinSalinity;
      const maxSal = data.MaxSalinity;

      popover = new bootstrap.Popover(element, {
        container: element.parentElement,
        content: formatCoordinate({
          sealID: sealID,
          maxDepth: maxDepth + "m",
          minTemp: minTemp,
          maxTemp: maxTemp,
          minSal: minSal,
          maxSal: maxSal
        }),
        html: true,
        offset: [0, 20],
        placement: 'top',
        sanitize: false,
      });
      popover.show();
    },
    error: function(error) {
      console.error("Error fetching data", error);
    }
  })
});

//can remove this
map.on('click', function(evt) {
  const coord = evt.coordinate; // Coordinate in the map's projection
  console.log('Map Coordinates:', coord);  // Log the coordinate in the map's projection
});

// Create the overlay for the fixed-size circle
const circleOverlayElement = document.getElementById('circle-overlay');
const circleOverlay = new Overlay({
  element: circleOverlayElement,
  position: [0, 0], // Centered at [0, 0]
  positioning: 'center-center'
});
map.addOverlay(circleOverlay);

// Function to update the circle size based on zoom level
function updateCircleSize() {
  const zoom = map.getView().getZoom();
  const baseSize = 200; // Base size of the circle in pixels
  const scaleFactor = Math.pow(2, zoom - 4); // Adjust the scale factor as needed
  const newSize = baseSize * scaleFactor;
  
  if (zoom >= map.getView().getMaxZoom()) {
    circleOverlayElement.style.display = 'none';
  } else {
    circleOverlayElement.style.display = 'block';
    circleOverlayElement.style.width = `${newSize}px`;
    circleOverlayElement.style.height = `${newSize}px`;
  }
}

// Initial update of the circle size
updateCircleSize();

// Update the circle size whenever the zoom level changes
map.getView().on('change:resolution', updateCircleSize);

document.getElementById('filter-toggle').addEventListener('click', function () {
    const filterOptions = document.getElementById('filter-options');
    filterOptions.classList.toggle('hidden');

    // Adjust the position of the toggle button when the container is hidden
    if (filterOptions.classList.contains('hidden')) {
        this.style.left = "calc(5% + 1em)";
        this.textContent = '→'; // Change to right arrow
    } else {
        this.style.left = "calc(15% + 1em)";
        this.textContent = '←'; // Change to left arrow
    }
});

document.getElementById('track-toggle').addEventListener('click', function () {
    const trackOptions = document.getElementById('track-options');
    trackOptions.classList.toggle('hidden');

    // Adjust the position of the toggle button when the container is hidden
    if (trackOptions.classList.contains('hidden')) {
        this.style.right = "calc(5% + 1em)";
        this.textContent = '←'; // Change to left arrow
    } else {
        this.style.right = "calc(15% + 1em)";
        this.textContent = '→'; // Change to right arrow
    }
});

asynch_Query();
